.newsletter-container {
  border-radius: 8px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  max-width: 700px;
  margin: 0 auto; /* Center the container itself */
}

.signup-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.email-input {
  flex: 1;
  padding: 12px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  text-align: left; /* Align text to the left */
}

.submit-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

@media (max-width: 415px) {
  .email-input {
    max-width: 180px !important;
  }
}

.submit-button:hover {
  background-color: #27ae60;
}

.underline-green {
  text-decoration: underline;
  text-decoration-color: green;
  text-decoration-thickness: 4px;
}