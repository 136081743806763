body {
    font-family: 'dsText', 'Roboto', sans-serif;
    font-weight: 400;
    background-color: #F0F2F5;
}

.navbar {
    background-color: transparent;
    padding: 1rem 2rem;
}

.nav-link {
    color: #000;
    margin-right: 1rem;
}

.nav-link:hover {
    color: #555;
}

.search-bar {
    background-color: #E9EBEE;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
}

.main-heading {
    color: #000;
    font-weight: 500;
    font-size: 48px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-left: 2rem;
}

.card {
    border-radius: 20px;
    padding: 2rem;
    margin: 1rem;
}

.card-title {
    font-size: 24px;
    color: #000;
    font-weight: 500;
}

.card-text {
    font-size: 18px;
}
.card .btn {
    background-color: #E9EBEE;
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.card .btn:hover {
    background-color: #d1d3d6;
    color: #000;
    transition: background-color 0.3s ease, color 0.3s ease;
}


.card-img-top {
    border-radius: 15px;
    margin-bottom: 1rem;
    height: 20vh;
    width: 100%;
    object-fit: cover !important;
}

.card:nth-child(1) {
    /* background: linear-gradient(145deg, #6e3ec2 0%, #a044ff 100%); */
    color: #fff;
}

.card:nth-child(2) {
    background-color: #34C759;
    color: #fff;
}

.card:nth-child(3) {
    background-color: #FF9500;
    color: #fff;
}

.navbar-toggler-icon{
    font-size: 0.9em;
}

