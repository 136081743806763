@font-face {
    font-family: 'dsText';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/NeueHaasUnicaW1G-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'dsText Fallback';
    font-style: normal;
    font-weight: 400;
    src: local('Arial');
    ascent-override: 89.53%;
    descent-override: 22.73%;
    line-gap-override: 0.00%;
    size-adjust: 100.30%;
}

@font-face {
    font-family: 'dsText';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/NeueHaasUnicaW1G-Medium.woff2') format('woff2');
}
  
@font-face {
    font-family: 'dsText Fallback';
    font-style: normal;
    font-weight: 500;
    src: local('Arial');
    ascent-override: 87.68%;
    descent-override: 24.01%;
    line-gap-override: 0.00%;
    size-adjust: 102.88%;
}

@font-face {
    font-family: 'dsText';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/NeueHaasUnicaW1G-Bold.woff2') format('woff2');
}
  
@font-face {
    font-family: 'dsText Fallback';
    font-style: normal;
    font-weight: 600;
    src: local('Arial Bold');
    ascent-override: 92.97%;
    descent-override: 27.30%;
    line-gap-override: 0.00%;
    size-adjust: 97.45%;
}

@font-face {
    font-family: 'dsHeading';
    src: url('../fonts/TinkoffSans.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: 'dsHeading Fallback';
    font-style: normal;
    font-weight: 400;
    src: local('Arial');
    ascent-override: 103.36%;
    descent-override: 36.00%;
    line-gap-override: 0.00%;
    size-adjust: 88.33%;
}

@font-face {
    font-family: 'dsHeading';
    src: url('../fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: 'dsHeading Fallback';
    font-style: normal;
    font-weight: 500;
    src: local('Arial');
    ascent-override: 93.77%;
    descent-override: 32.66%;
    line-gap-override: 0.00%;
    size-adjust: 97.37%;
}

@font-face {
    font-family: 'dsHeading';
    
    src: url('../fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: 'dsHeading Fallback';
    font-style: normal;
    font-weight: 600;
    src: local('Arial');
    ascent-override: 89.11%;
    descent-override: 31.04%;
    line-gap-override: 0.00%;
    size-adjust: 102.46%;
}

h1, h2, h3, h4, h5, h6, p {
    color: black;
    text-decoration: none !important;
    
}

p {
    font-family: 'dsText';
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6
{
    margin-top: 2rem;
    font-family: 'dsHeading';
}

.MuiTreeItem-content {
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
}

.MuiTreeItem-content:hover {
    transform: scale(1.075);
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-weight: bold;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
    
}

li {
    margin-top: 5px;
}

code {
    background-color: #f2f2f2; /* Dark background */
    color: #1357d2; /* Light text color */
    padding: 5px; /* Padding around the code */
    border-radius: 3px; /* Rounded corners */
    font-family: 'Courier New', Courier, monospace; /* Monospaced font */
}

td code {
    white-space: nowrap;
}

p code {
    white-space: nowrap;
}

p {
    line-height: 1.7; /* Adjust the value as needed */
}

aside {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 15px;
    transition: transform 0.3s ease-in-out;
    background-color: #ffffff !important;
}

aside:hover {
    transform: scale(1.025);
    transition: transform 0.4s ease-in-out;
}

.language-sql {
    color: #3d3d3d !important; /* Light text color */
    background-color: #f7f7f700; /* Dark background */
}

@media (min-width: 1200px) {
    .container{
        max-width: 1350px !important;
    }
}


.custom-container {
    /* background-color: #ffffff; /* Or any hex color code */
    /* color: white;  Text color */
    border-radius: 50px; /* Adjust the value for more/less rounded corners */
    margin-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 5%;
    padding-right: 5%;
}

figure {
    width: 100% !important;
}

.p-timeline {
    height: 50px;
}

figure > div {
    padding-bottom: 10px !important; 
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'dsText', sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
}

th {
    background-color: #f2f2f2;
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    border-radius: 10px;
}

td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

tr:hover {
    background-color: #f5f5f5;
}

/* For zebra striping */
tr:nth-child(even) {
    background-color: #f9f9f9;
}

pre {
    background-color: #f5f5f5; /* Light grey background */
    border-left: 4px solid #007acc; /* Blue left border */
    padding: 10px; /* Padding around the code block */
    font-family: 'Courier New', Courier, monospace; /* Monospaced font */
    font-size: 0.9em; /* Slightly smaller font size */
    overflow-x: auto; /* Horizontal scroll for long code lines */
    border-radius: 5px; /* Rounded corners */
    margin: 10px 0; /* Margin above and below the code block */
    transition: background-color 0.3s ease-in-out; 
}

pre:hover {
    background-color: #ebebeb;
    transition: background-color 0.3s ease-in-out; 
    cursor: pointer;
}

.p-timeline:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out; 
}

.MuiTimelineItem-root::before {
    padding: 0;
    content: none !important;
}

.MuiTimelineConnector-root {
    flex-grow: 0 !important;
    height: 18px !important;
}

.module {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.centered {
    display: flex;
    justify-content: center;
}

.ignore-underline-course, .dwLkZV {
    text-decoration: none;
    color: #000000;
}

.root {
    background: linear-gradient(135deg, #e6e6e6, #cddbff);
}

.card-header {
    padding: 2rem;
    margin: 1rem;
}

.card-course {
    border-radius: 50px !important;
    padding: 2rem;
    margin: 1rem;
    border: none;
}

@media (max-width: 600px) {
    .card-course {
        padding: 0 !important;
    }
}

.card:nth-child(1) {
    background: white;
}

.card:nth-child(2) {
    background: white;
}

.card:nth-child(3) {
    background: white;
}

svg {
    display: inline;
    vertical-align: middle;
    margin-left: 10px;
    height: 20px;
    display: inline; /* Change to display: inline for all elements */
    vertical-align: middle; /* Align the elements vertically */
}

a, span, .fxzLyt {
    display: inline; /* Change to display: inline for all elements */
    vertical-align: middle; /* Align the elements vertically */
}

li, .token, .language-sql {
    color: black;
}

.not-printable {
    display: none;
}

.result-table {
    border: 1px solid black;
    color: black;
}

