.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 20px 30px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #e6e6e6;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
}

.cookie-consent-banner h3 {
    font-size: 1.5rem;
    margin: 0 0 15px 0;
    color: #333333;
}

.cookie-consent-banner p {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666666;
    max-width: 800px;
}

.cookie-consent-button {
    padding: 10px 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.btn-success {
    background-color: #4CAF50;
    color: white;
}

.btn-success:hover {
    background-color: #45a049;
}

.btn-outline {
    background-color: transparent;
    border: 2px solid #4CAF50;
    color: #4CAF50;
}

.btn-outline:hover {
    background-color: #4CAF50;
    color: white;
}

.btn-grayscale {
    background-color: #f1f1f1;
    color: #666666;
}

.btn-grayscale:hover {
    background-color: #e1e1e1;
}

.cookie-consent-options {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.cookie-consent-options label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #444444;
    font-size: 0.9rem;
}

.cookie-consent-options input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #4CAF50;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}

.cookie-consent-options input[type="checkbox"]:checked {
    background-color: #4CAF50;
}

.cookie-consent-options input[type="checkbox"]:checked::before {
    content: "✓";
    position: absolute;
    color: white;
    font-size: 12px;
    font-weight: bold;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.cookie-consent-options input[type="checkbox"]:disabled {
    border-color: #cccccc;
    background-color: #f1f1f1;
    cursor: not-allowed;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .cookie-consent-banner {
        padding: 15px 20px;
    }

    .cookie-consent-banner h3 {
        font-size: 1.2rem;
    }

    .cookie-consent-banner p {
        font-size: 0.9rem;
    }

    .cookie-consent-button {
        width: 100%;
        margin-right: 0;
    }

    .cookie-consent-options {
        flex-direction: column;
        gap: 10px;
    }
}

/* Animation */
@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.cookie-consent-banner {
    animation: slideUp 0.5s ease-out;
}

/* Dark Mode Support 
@media (prefers-color-scheme: dark) {
    .cookie-consent-banner {
        background-color: #2d2d2d;
        border-top: 1px solid #3d3d3d;
    }

    .cookie-consent-banner h3 {
        color: #ffffff;
    }

    .cookie-consent-banner p {
        color: #cccccc;
    }

    .btn-grayscale {
        background-color: #3d3d3d;
        color: #ffffff;
    }

    .btn-grayscale:hover {
        background-color: #4d4d4d;
    }

    .cookie-consent-options label {
        color: #cccccc;
    }

    .cookie-consent-options input[type="checkbox"]:disabled {
        border-color: #4d4d4d;
        background-color: #3d3d3d;
    }
}*/