.container {
    max-width: 1200px;
}
.functions-card {
    border-radius: 18px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.ignore-underline {
    text-decoration: none;
    color: #555;
}

.ignore-underline:hover {
    color: #353535;
    font-weight: bold;
    transition: color 0.3s ease;
    transition: font-weight 0.1s ease;
}

