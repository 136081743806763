.header {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.task-list {
    margin-top: 20px;
}

.task-item {
    background-color: #f8f9fa;
    border: none;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.task-item:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    cursor: pointer;

}


.task-item .i-easy {
    color: #28a745;
    margin-right: 10px;
}

.task-item .i-middle {
    color: #a79f28;
    margin-right: 10px;
}

.task-item .i-hard {
    color: #a72828;
    margin-right: 10px;
}

.task-text {
    flex-grow: 1;
}
.sidebar {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
}
.search-box {
    position: relative;
    margin-bottom: 20px;
}
.search-box input {
    width: 100%;
    padding: 10px;
    padding-right: 40px;
    border: 1px solid #ced4da;
    border-radius: 20px;
}

.search-box i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ced4da;
}

.checkbox-group {
    margin-bottom: 10px;
}

.checkbox-group input {
    margin-right: 10px;
}

.promo-card {
    background-color: #ffca2c;
    padding: 20px;
    border-radius: 8px;
    color: #000;
    text-align: center;
    font-weight: 500;
}