.main-heading {
    overflow: hidden;
    border-right: .15em solid black;
    white-space: nowrap;
    padding-top: 0.5em;
    letter-spacing: .025em;
    animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
    animation-fill-mode: forwards; /* Ensures final state is retained */
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold !important;
}

@media (max-width: 768px) {
    .main-heading {
        font-size: 1.55rem !important;
        animation-duration: 2s !important; /* Faster animation on mobile */
    }
}

.accordionMui {
    border-radius: 10px !important;
    padding: 5px;
}

.card-main {
    border-radius: 50px !important;
    padding: 0.005rem;
    margin: 1.5rem;
}

.card {
    transform-style: preserve-3d;
    perspective: 1000px;
    transition: transform 0.3s ease-out;

    border-radius: 50px !important;
    padding: 2rem;
    margin: 1rem;
    border: none;
}

.card:hover {
   transform: translateY(-10px) rotateX(1deg);
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
}

.main-course-card {
    background: linear-gradient(to bottom, #ecfbfd, #ffffff17);
    margin-left: 0.01vw;
    margin-right: 0.01vw;
}

.css-ypmer8 {
    transition: transform 0.3s ease;
}

.css-ypmer8:hover {
    transform: scale(1.015);
}

.MuiAvatarGroup-root {
    margin-top: 1vh;
    margin-bottom: 1vh;
}