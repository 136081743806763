.i-easy {
  color: #28a745;
  margin-right: 5px;
}

.i-middle {
  color: #a79f28;
  margin-right: 5px;
}

.i-hard {
  color: #a72828;
  margin-right: 5px;
}

.gutter {
  background: rgba(255, 255, 255, 0.01);
  /* flex-shrink: 0; */
}

.gutter-horizontal {
  cursor: ew-resize;
}

.gutter-vertical {
  cursor: ns-resize;
}

.MuiTableCell-head {
  background-color: #f8f9fa !important;
}


.gutter:hover {
  transition: background-color 0.3s ease;
  background: rgba(163, 163, 163, 0.5);
}


.comp {
  background: red;
  color: white;
  height: 400px;
  /* display: inline-block; */
}

.comp2 {
  background: green;
  color: white;
  height: 400px;
  /* display: inline-block; */
}

.wrap {
  display: flex;
}

.locked-section {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.login-section {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.login-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.fa-lock {
  font-size: 24px;
  color: #6c757d;
}

.locked-section p {
  color: #6c757d;
  margin-top: 10px;
}

.login-section p {
  color: #6c757d;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  display: none;
}