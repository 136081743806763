.arrow-container {
    position: fixed;
    bottom: 18%;
    left: 92%;
    transform: translateX(-50%);
    z-index: 1000;
    pointer-events: none;
}

@media screen and (max-width: 1280px) {
    .arrow-container {
        bottom: 18%;
        left: 90%;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 768px) {
    .arrow-container {
        bottom: 18%;
        left: 85%;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 480px) {
    .arrow-container {
        bottom: 18%;
        left: 75%;
        transform: translateX(-50%);
    }
}

@media screen and (max-height: 680px) {
    .arrow-container {
        bottom: 25%;
        left: 75%;
        transform: translateX(-50%);
    }
}


  
.curved-arrow {
transform: rotate(45deg);
animation: bounce 2s infinite;
}

@keyframes bounce {
0%, 100% {
    transform: rotate(45deg) translateY(0);
}
50% {
    transform: rotate(45deg) translateY(-10px);
}
}